import * as React from "react";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Copyright from '../components/Copyright';

export default function About() {
  const { t } = useTranslation("translations");
  const {languages, originalPath} = useI18next();
  return (
    <Container maxWidth="sm">

<ul className="languages">
        {languages.map((lng) => (
          <li key={lng}>
            <Link to={originalPath} language={lng}>
              {lng}
            </Link>
          </li>
        ))}
      </ul>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gatsby example
        </Typography>
        <h1>{t("hello")}</h1>
        <Typography variant="h4" component="h1" gutterBottom>
          <Trans>
            hello
          </Trans>
        </Typography>
        <Link to="/">Go to the main page</Link>
        <Copyright />
      </Box>
    </Container>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;